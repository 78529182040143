import APP_CONFIG from "../config";
const urljoin = require('url-join');
export const BASE_URL = APP_CONFIG.server.uri;
const api_v1 = "v1.0";

export const API_URL = {

    // AUTH
    AUTH_REGISTER: urljoin(BASE_URL, api_v1, `/user/register`),
    AUTH_LOGIN: urljoin(BASE_URL, api_v1, `/user/login`),
    USER_PROFILE: urljoin(BASE_URL, api_v1, `/user/profile`),
    FORGOT_PASS: urljoin(BASE_URL, api_v1, `/user/changePassword`),
    RESET_PASS: urljoin(BASE_URL, api_v1, `/user/resetPassword`),
    SEND_OTP: urljoin(BASE_URL, api_v1, `/user/register/verification/email/send-otp`),
    // USER SUBSCRIPTION
    GET_SUBSCRIPTION: urljoin(BASE_URL, api_v1, `/user/subscriptions`),
    ADD_SUBSCRIPTION: urljoin(BASE_URL, api_v1, `/user/subscriptions/subscribe`),
    REMOVE_SUBSCRIPTION: (subscription_id) => urljoin(BASE_URL, api_v1, `/user/subscriptions/${subscription_id}/unsubsribe`),

    // USER MEMBERSHIP
    MY_MEMBERSHIP: urljoin(BASE_URL, api_v1, `/membership`),
    MY_PLANS: urljoin(BASE_URL, api_v1, `/membership/plans`),
    BUY_MEMBERSHIP: urljoin(BASE_URL, api_v1, `/membership/buy`),

    // GRIEVANCE
    GRIEVANCE: urljoin(BASE_URL, api_v1, `/grievance`),


    // BULLETIN
    BULLETIN: urljoin(BASE_URL, api_v1, `/bulletin/latest`),

    // UPLOAD FILE
    UPLOAD_FILE: urljoin(BASE_URL, api_v1, `/file-store`),

    // NEWS
    LATEST_NEWS: urljoin(BASE_URL, api_v1, `/news`),

    // PAYMENT
    PAYMENT: urljoin(BASE_URL, api_v1, `/payment/pay`),

    // MEMBERS
    MEMBERS: urljoin(BASE_URL, api_v1, `/user/icpa-members`),
    COMMITTEE_MEMBERS: urljoin(BASE_URL, api_v1, `/user/icpa-committee-members`),


    // DOCUMENTS
    DOCUMENTS: {
        LETTER: urljoin(BASE_URL, api_v1, `/document/letter`),
        CIRCULAR: urljoin(BASE_URL, api_v1, `/document/circular`),
        DIRECTIVE: urljoin(BASE_URL, api_v1, `/document/directive`),
        FLIGHT_SAFETY: urljoin(BASE_URL, api_v1, `/document/flight-safety`),
        MISCELLANEOUS: urljoin(BASE_URL, api_v1, `/document/miscellaneous`),
    },

    // SCHEMES
    SCHEMES: {
        GMC: urljoin(BASE_URL, api_v1, `/policy/gmc`),
        GMC_MAILPOLICY: urljoin(BASE_URL, api_v1, `/policy/gmc/mail_my_policy`),
        TMU: urljoin(BASE_URL, api_v1, `/scheme/medical-unfit/temporary`),
        PMU: urljoin(BASE_URL, api_v1, `/scheme/medical-unfit/permanent`),
        INCIDENT: urljoin(BASE_URL, api_v1, `/scheme/grounding/incident`),
        ACCIDENT: urljoin(BASE_URL, api_v1, `/scheme/grounding/accident`),
        ADMIN: urljoin(BASE_URL, api_v1, `/scheme/grounding/administrative`),
        MY: urljoin(BASE_URL, api_v1, `/scheme/my`),
        CMWS: urljoin(BASE_URL, api_v1, `/scheme/central-mutual-welfare`),
        GMCDOWNLOAD: urljoin(BASE_URL, api_v1, `/policy/gmc/pdf/`)
    },

    // STATIC MARKDOWN
    TERMS_N_CONDITIONS: urljoin(BASE_URL, `/static/assets/markdown/terms-and-conditions.md`),
    ICPA_CONSTITUTION: urljoin(BASE_URL, `/static/assets/markdown/icpa-constitution.md`),
    WELCOME_DASHBOARD: urljoin(BASE_URL, `/static/assets/markdown/pilots-dashboard-welcome.md`),

}