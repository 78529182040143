import { useJwt } from '../services/jwt.service';
import axios from 'axios';
import { API_URL } from '../constants/api.constant';
const urljoin = require('url-join');

const authAxios = useJwt.getAuthenticatedInterceptor(); // Use authenticated axios for authenticated requests


export const API = {

    // AUTHENTICATION
    LOGIN: (...args) => axios.post(API_URL.AUTH_LOGIN, ...args),
    REGISTER: (...args) => axios.post(API_URL.AUTH_REGISTER, ...args),
    SEND_OTP: (...args) => axios.post(API_URL.SEND_OTP, ...args),
    PROFILE: (...args) => authAxios.get(API_URL.USER_PROFILE, ...args),
    FORGOT: (...args) => axios.post(API_URL.FORGOT_PASS, ...args),
    RESET: (...args) => axios.post(API_URL.RESET_PASS, ...args),
    PROFILEUPDATE: (...args) => authAxios.post(API_URL.USER_PROFILE, ...args),

    // USER SUBSCRIPTION
    GET_SUBSCRIPTION: (...args) => authAxios.get(API_URL.GET_SUBSCRIPTION, ...args),
    ADD_SUBSCRIPTION_DEVICE: (...args) => authAxios.post(API_URL.ADD_SUBSCRIPTION, ...args),
    REMOVE_SUBSCRIPTION_DEVICE: (id, ...args) => authAxios.post(API_URL.REMOVE_SUBSCRIPTION(id), ...args),
    // USER MEMBERSHIP
    MY_MEMBERSHIP: (...args) => authAxios.get(API_URL.MY_MEMBERSHIP, ...args),
    MY_PLANS: (...args) => authAxios.get(API_URL.MY_PLANS, ...args),
    BUY_MEMBERSHIP: (...args) => authAxios.post(API_URL.BUY_MEMBERSHIP, ...args),
    // ADD_SUBSCRIPTION_DEVICE: (...args) => authAxios.post(API_URL.ADD_SUBSCRIPTION, ...args),
    // REMOVE_SUBSCRIPTION_DEVICE: (id, ...args) => authAxios.post(API_URL.REMOVE_SUBSCRIPTION(id), ...args),

    // BULLETIN
    BULLETIN: (...args) => authAxios.get(API_URL.BULLETIN, ...args),

    // UPLOAD_FILE
    // UPLOAD_FILE: (...args) => authAxios.put(API_URL.UPLOAD_FILE, ...args),
    UPLOAD_FILE: (progressUpdate, ...args) => authAxios.put(API_URL.UPLOAD_FILE, ...args, { onUploadProgress: progressUpdate }),

    // GRIEVANCE
    PUT_GRIEVANCE: (...args) => authAxios.put(API_URL.GRIEVANCE, ...args),
    GET_ALL_GRIEVANCES: (...args) => authAxios.get(API_URL.GRIEVANCE, ...args),
    GET_GRIEVANCE: (id = '', ...args) => authAxios.get(urljoin(API_URL.GRIEVANCE, id), ...args),

    //  PAYMENT 
    PAYMENT: (...args) => authAxios.post(API_URL.PAYMENT, ...args),

    // LATEST NEWS
    LATEST_NEWS: (...args) => authAxios.get(API_URL.LATEST_NEWS, ...args),

    // MEMBERS
    MEMBERS: (...args) => authAxios.get(API_URL.MEMBERS, ...args),
    COMMITTEE_MEMBERS: (...args) => authAxios.get(API_URL.COMMITTEE_MEMBERS, ...args),

    // DOCUMENTS
    LETTER: (id = '', ...args) => authAxios.get(urljoin(API_URL.DOCUMENTS.LETTER, id), ...args),
    CIRCULAR: (id = '', ...args) => authAxios.get(urljoin(API_URL.DOCUMENTS.CIRCULAR, id), ...args),
    DIRECTIVE: (id = '', ...args) => authAxios.get(urljoin(API_URL.DOCUMENTS.DIRECTIVE, id), ...args),
    FLIGHT_SAFETY: (id = '', ...args) => authAxios.get(urljoin(API_URL.DOCUMENTS.FLIGHT_SAFETY, id), ...args),
    MISCELLANEOUS: (id = '', ...args) => authAxios.get(urljoin(API_URL.DOCUMENTS.MISCELLANEOUS, id), ...args),

    // SCHEMES
    TMU: (...args) => authAxios.post(API_URL.SCHEMES.TMU, ...args),
    PMU: (...args) => authAxios.post(API_URL.SCHEMES.PMU, ...args),
    GMC: (...args) => authAxios.post(API_URL.SCHEMES.GMC, ...args),
    SUBMIT_CMWS: (...args) => authAxios.post(API_URL.SCHEMES.CMWS, ...args),
    GET_GMC: (...args) => authAxios.get(API_URL.SCHEMES.GMC, ...args),
    GET_GMC_SUMMARY: (...args) => authAxios.get(API_URL.SCHEMES.GMCDOWNLOAD, ...args, { responseType: 'blob' }),
    GET_GMC_MAIL: (...args) => authAxios.get(API_URL.SCHEMES.GMC_MAILPOLICY, ...args),
    GET_GMCPAYMENT_LINK: (id = '', ...args) => authAxios.get(urljoin(API_URL.SCHEMES.GMC, id, "payment_link"), ...args),

    INCIDENT: (...args) => authAxios.post(API_URL.SCHEMES.INCIDENT, ...args),
    ACCIDENT: (...args) => authAxios.post(API_URL.SCHEMES.ACCIDENT, ...args),
    ADMIN: (...args) => authAxios.post(API_URL.SCHEMES.ADMIN, ...args),
    MY_ALL_SCHEMES: (...args) => authAxios.get(API_URL.SCHEMES.MY, ...args),
    MY_SCHEME: (id = '', ...args) => authAxios.get(urljoin(API_URL.SCHEMES.MY, id), ...args),

    // STATIC MARKDOWN
    TERMS_N_CONDITIONS: (...args) => axios.get(API_URL.TERMS_N_CONDITIONS, ...args),
    ICPA_CONSTITUTION: (...args) => axios.get(API_URL.ICPA_CONSTITUTION, ...args),
    WELCOME_DASHBOARD: (...args) => axios.get(API_URL.WELCOME_DASHBOARD, ...args),


}